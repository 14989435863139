import { axiosInstance } from "./constant";
import config from "../config";
import { GetEncryptUser } from "functions/encryption";

var baseUrlUser = `${config.API_URL}/api/v1/auth`;
var baseUrlDecisions = `${config.API_URL}/api/v1/dms`;
var baseUrlLos = `${config.API_URL}/api/v1/los`;
var baseUrlCms = `${config.API_URL}/api/v1/cms`;
var baseUrlSMS = `${config.API_URL}/api/v1/sms`;

async function CheckQuestionStatusInScreen(id) {
  try {
    const response = await axiosInstance.get(
      baseUrlDecisions + `/screen/questionCheckInScreen?questionId=${id}`
    );
    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return message;
  }
}

async function GetSeelahHistory() {
  try {
    const response = await axiosInstance.get(baseUrlCms + `/selaApi/history`);
    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return message;
  }
}

async function UpdateDbr(data) {
  try {
    const response = await axiosInstance.post(
      `${baseUrlLos}/dbr/calculation/dbrcalculation`,
      data
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Response error:", error.response.data);
      console.error("Status code:", error.response.status);
      console.error("Headers:", error.response.headers);
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Network error:", error.request);
      return "Network error: No response received from the server.";
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error:", error.message);
      return error.message;
    }
  }
}
async function RadeemMoney(id) {
  try {
    const response = await axiosInstance.post(
      baseUrlCms + `/selaApi/redeem?ownershipId=${id}`
    );
    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return message;
  }
}
async function GetAllOwnersShipIds() {
  try {
    const response = await axiosInstance.get(
      baseUrlCms + `/selaApi/getAllOwnerShipIds`
    );
    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return message;
  }
}

async function TopUpWalletFunction(data) {
  try {
    const response = await axiosInstance.post(
      baseUrlCms + `/selaApi/topUpWalletAmount`,
      data
    );
    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return message;
  }
}

async function TransferRajhi(id) {
  try {
    const response = await axiosInstance.post(
      baseUrlLos +
        `/alrajhi/transfer/paymentTransferBusinessToCustomer?userId=${id}`
    );
    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return message;
  }
}

async function TransactionHistory(id) {
  try {
    const response = await axiosInstance.get(
      baseUrlLos + `/alrajhi/transfer/fetchUserTransactions?userId=${id}`
    );
    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return message;
  }
}

async function ResetFailedAttemps(id) {
  try {
    const response = await axiosInstance.post(
      baseUrlUser + `/user/resetFailedAttempts?idNumber=${id}`
    );

    return {
      status: response?.status,
      message: response?.data?.message,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";
    return { status: status, message: message };
  }
}

async function UpdatePermissions(id, permissions) {
  try {
    const response = await axiosInstance.put(
      baseUrlUser + `/role/${id}/permissions?permissionIds=${permissions}`
    );

    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return error.response;
  }
}

async function BankCreate(payload) {
  try {
    const response = await axiosInstance.post(
      baseUrlLos + `/seulah/bank`,
      payload
    );

    return response;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return error.response;
  }
}

async function GetBankList() {
  try {
    const response = await axiosInstance.get(baseUrlLos + `/seulah/bank`);

    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return error.response;
  }
}
async function DeleteBank(payload) {
  try {
    const response = await axiosInstance.delete(
      baseUrlLos + `/seulah/bank?accountType=${payload}`
    );

    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return error.response;
  }
}

async function AsyncCountries(payload) {
  try {
    const response = await axiosInstance.put(
      baseUrlLos + `/simah/countries/sync`
    );

    return response;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return error.response;
  }
}
async function AddBlackListCountry(payload) {
  let temp;
  if (payload?.discription) {
    temp = `/country/${payload.country}/blacklist?blacklistReason=${
      payload?.discription
    }&blacklistedById=${User()?.user?.id}`;
  }
  if (!payload?.discription) {
    temp = `/country/${payload.country}/blacklist?blacklistedById=${
      User()?.user?.id
    }`;
  }
  try {
    const response = await axiosInstance.put(baseUrlLos + temp);

    return response;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return error.response;
  }
}

async function AddNewSeulahBankCodes(payload) {
  try {
    const response = await axiosInstance.post(
      baseUrlLos + "/bankCode/createBankCodes",
      payload
    );

    return {
      status: response?.status,
      message: response?.data?.messsage,
      response: response?.data,
    };
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return error.response;
  }
}

async function DeleteCountryFromBlackList(payload) {
  try {
    const response = await axiosInstance.put(
      baseUrlLos + `/country/${payload}/whitelist`
    );

    return response;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return error.response;
  }
}

async function DeleteSeulahBankCode(payload) {
  try {
    const response = await axiosInstance.delete(
      baseUrlLos + `/bankCode/deleteBankCodeById/${payload}`
    );

    return {
      status: response?.status,
      message: response?.data?.messsage,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";

    return { status: status, message: message };
  }
}

async function UpdatePolicyOther(payload) {
  try {
    const response = await axiosInstance.post(
      baseUrlDecisions +
        `/policy/update?policyId=${payload?.policyId}&userId=${payload?.userId}`,
      payload?.data
    );

    return response;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return error.response;
  }
}
async function GetSeelaInvestMent(id) {
  try {
    const response = await axiosInstance.get(
      baseUrlCms + `/selaApi/buy-ownerships-by_user?userId=${id}`
    );
    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return message;
  }
}
async function GetSelaaSellRadeemValues(id) {
  try {
    const response = await axiosInstance.get(
      baseUrlCms + `/selaApi/transfer-redeem-by-user?userId=${id}`
    );
    return response.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : "An error occurred";
    return message;
  }
}
async function SeelaOperationBuy(payload) {
  try {
    const response = await axiosInstance.post(
      baseUrlCms + `/selaApi/buy`,
      payload
    );

    return { status: response?.status, message: response?.data?.messsage };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message
      : "An error occurred";

    return { status: status, message: message };
  }
}

async function TransferInvetmentCertificate(data) {
  try {
    const response = await axiosInstance.post(
      baseUrlCms + `/selaApi/transfer`,
      data
    );
    return {
      status: response?.status,
      message: response?.data?.messsage,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";

    return { status: status, message: message };
  }
}
async function RadeemInvestmentSelaa(data) {
  try {
    const response = await axiosInstance.post(
      baseUrlCms +
        `/selaApi/redeem?ownershipId=${data?.ownershipId}&userId=${data?.userId}&applicationReview=${data?.applicationReview}`
    );
    return {
      status: response?.status,
      message: response?.data?.messsage,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";

    return { status: status, message: message };
  }
}
async function GetOwnerShipIdOfApplication(data) {
  try {
    const response = await axiosInstance.post(
      baseUrlCms + `/selaApi/buy-ownerships`,
      data
    );

    return {
      status: response?.status,
      message: response?.data?.messsage,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";

    return { status: status, message: message };
  }
}

async function GetOwnerShipIdRadeem(data) {
  try {
    const response = await axiosInstance.get(
      baseUrlCms + `/selaApi/fetchFailedRedeemInvestment?userId=${data}`
    );

    return {
      status: response?.status,
      message: response?.data?.messsage,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";

    return { status: status, message: message };
  }
}

async function GetPoliciesSpecificValues(data) {
  try {
    const response = await axiosInstance.get(
      baseUrlDecisions + `/policy/fetchPoliciesByName?${data}`
    );

    return {
      status: response?.status,
      message: response?.data?.messsage,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";

    return { status: status, message: message };
  }
}

async function GetEmdahReport(payload) {
  try {
    const response = await axiosInstance.get(
      baseUrlCms + `/doc/getAgreementByUserId?idNumber=${payload}`
    );

    return {
      status: response?.status,
      message: response?.data?.messsage,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";

    return { status: status, message: message };
  }
}
async function UpdateSimahProductStatus(payload, params) {
  try {
    const response = await axiosInstance.put(
      baseUrlLos +
        `/simah/product/${payload.id}/enable?enablePerformance=${params?.enablePerformance}&enableDbr=${params?.enableDbr}`
    );
    return {
      status: response?.status,
      message: response?.data?.messsage,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";
    return { status: status, message: message };
  }
}

async function CreateNotification(payload) {
  try {
    const response = await axiosInstance.post(
      baseUrlSMS + `/notifications/sendNotificationToAllUsers`,
      payload
    );

    return {
      status: response?.status,
      message: response?.data?.message,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";
    return { status: status, message: message };
  }
}

async function GetLoanHistoryOfUser(payload) {
  try {
    const response = await axiosInstance.get(
      baseUrlLos +
        `/loanTypeFormula/loanApplication/${payload?.applicationId}/audit?page=${payload?.pageNumber}&size=${payload?.pageSize}`
    );

    return {
      status: response?.status,
      message: response?.data?.message,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";
    return { status: status, message: message };
  }
}

async function GetUserApplicationStatusTracking(payload) {
  try {
    const response = await axiosInstance.get(
      baseUrlUser + `/user/getApplicationStatus?userId=${payload}`
    );

    return {
      status: response?.status,
      message: response?.data?.message,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";
    return { status: status, message: message };
  }
}

async function GetUserAccountDetails(payload) {
  try {
    const response = await axiosInstance.get(
      baseUrlLos + `/alrajhi/${payload}/getAccountDetail`
    );

    return {
      error: response?.data?.error,
      message: response?.data?.message,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";
    return { status: status, message: message };
  }
}
async function GetSeulahBankCodes() {
  try {
    const response = await axiosInstance.get(baseUrlLos + `/bankCode`);

    return {
      error: response?.data?.error,
      message: response?.data?.message,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";
    return { status: status, message: message };
  }
}
async function GetLeanDetailsData(payload) {
  try {
    const response = await axiosInstance.get(
      baseUrlCms + `/lean/getIbanStatus?idNumber=${payload}`
    );

    return {
      status: response?.status,
      message: response?.data?.message,
      response: response?.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null; // Error status code
    const message = error.response
      ? error.response.data.message || error?.message
      : "An error occurred";
    return { status: status, message: message };
  }
}
export {
  GetUserAccountDetails,
  GetUserApplicationStatusTracking,
  GetLoanHistoryOfUser,
  CreateNotification,
  UpdateSimahProductStatus,
  GetOwnerShipIdRadeem,
  GetEmdahReport,
  GetPoliciesSpecificValues,
  GetOwnerShipIdOfApplication,
  RadeemInvestmentSelaa,
  TransferInvetmentCertificate,
  GetSelaaSellRadeemValues,
  SeelaOperationBuy,
  GetSeelaInvestMent,
  UpdatePolicyOther,
  AsyncCountries,
  DeleteBank,
  CheckQuestionStatusInScreen,
  UpdateDbr,
  GetSeelahHistory,
  RadeemMoney,
  GetAllOwnersShipIds,
  TopUpWalletFunction,
  TransferRajhi,
  TransactionHistory,
  ResetFailedAttemps,
  UpdatePermissions,
  BankCreate,
  GetBankList,
  AddBlackListCountry,
  DeleteCountryFromBlackList,
  GetSeulahBankCodes,
  DeleteSeulahBankCode,
  AddNewSeulahBankCodes,
  GetLeanDetailsData,
};
function User() {
  const user = GetEncryptUser();

  if (user) {
    if (user?.data) {
      return user?.data;
    } else {
      return null;
    }
  } else {
    return null;
  }
}
