import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetLeanDetailsData } from "Services/OtherApis";
import moment from "moment";
function Lean() {
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ID = queryParams.get("id");

  useEffect(() => {
    getLeanDetails();
  }, []);
  function getLeanDetails() {
    setLoading(true);
    // "ID"
    // 1106972886
    GetLeanDetailsData("1106972886")
      .then((data) => {
        console.log("data", data?.response?.data);
        if (data?.response?.data?.leanResponse) {
          console.log("");
          setData(JSON.parse(data?.response?.data?.leanResponse));
          setData2(data?.response?.data);
        }
      })
      .catch((error) => {
        //  dispatch(action.Message({ open: true, message: "Error", error: true }));
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <div className="w-full">
      <div className="w-full flex justify-center">
        {loading && data === null ? (
          <div className="py-20 px-20 text-center w-full dark:text-dark0">
            <span>{"Loading..."}</span>
          </div>
        ) : !loading && data === null ? (
          <div className="py-20 px-20 text-center w-full dark:text-dark0">
            <span>{"Nothing to show!"}</span>
          </div>
        ) : data ? (
          <>
            {data ? (
              <div className="w-full overflow-x-auto">
                <table className="w-full border border-gray-400 text-left text-sm md:text-md table-auto">
                  <thead>
                    <tr className="bg-gray-200 text-gray-700">
                      <th className="px-4 py-2 border">Field</th>
                      <th className="px-4 py-2 border">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Id Number
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data2?.idNumber || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Iban ownership verified
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.verifications?.iban_ownership_verified
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Account Status
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.verifications?.account_status || "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        IBAN
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data2?.iban || "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Bank Name English
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.verifications?.bank_name?.en || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {" "}
                        Bank Name Arabic
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.verifications?.bank_name?.ar || "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Swift Code
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.verifications?.swift_code || "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Bank Code
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.verifications?.bank_code || "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Account holder name
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.verifications?.account_holder_name || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Verification Method
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.verifications?.verification_method || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Results id
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.results_id || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Message
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {data?.message || "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        Timestamp
                      </td>
                      <td className="px-4 py-2 border whitespace-nowrap">
                        {moment(data?.timestamp).format("LLLL") || "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="w-full text-center text-gray-700">
                No Data Found!
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Lean;
