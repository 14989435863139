import React from "react";
import { useTranslation } from "react-i18next";
import { getLanguage } from "functions/getLanguage";

function Table({ children, header, styleMain }) {
  const { t } = useTranslation();

  return (
    <div className={`overflow-x-auto relative  mt-4 ${styleMain}`}>
      <table
        className={`w-full whitespace-nowrap  text-sm  text-gray-500 dark:text-gray-400 ${
          getLanguage() === "ar" ? "text-right" : "text-left"
        }`}
      >
        <thead className="text-xs text-gray-400 uppercase bg-gray-200 dark:bg-dark3 font-normal">
          <tr>
            {header?.map((v, key) => (
              <th scope="col" className={`px-3 py-3 ${v?.style} `}>
                {t(v?.name)}
              </th>
            ))}
          </tr>
        </thead>
        {children}
      </table>
    </div>
  );
}

export default Table;
