import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CardMain from "../../../Components/Cards/main";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import withAuthorization from "../../../constants/authorization";
import { GetSeelahHistory } from "Services/OtherApis";
import * as action from "../../../Services/redux/reducer";
import Table from "Components/Table";

function AllUsers() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  useEffect(() => {
    getAllUsersData();
  }, []);
  function getAllUsersData() {
    dispatch(action.Loading({ Loading: true }));
    GetSeelahHistory()
      .then(
        (data) => (setData(data), dispatch(action.Loading({ Loading: false })))
      )
      .catch((error) => dispatch(action.Loading({ Loading: true })));
  }

  function formatNumber(value) {
    if (isNaN(value)) return "0.00";
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return (
    <div className="py-5">
      <CardMain
        width="w-full"
        heading={t("Investment and topup transactions history")}
        iconStyle="text-3xl text-primary"
      >
        {/* Wrapper for both X and Y scrolling */}
        <div className="relative h-[70vh] overflow-hidden">
          <div className="overflow-x-auto overflow-y-auto h-full">
            <Table header={header}>
              <tbody className="dark:text-dark0">
                {data?.map((v, k) => (
                  <tr
                    key={k}
                    className={`border-b border-gray-100 dark:border-dark1 ${
                      k % 2
                        ? "bg-gray-50 dark:bg-gray-600"
                        : "bg-white dark:bg-gray-700"
                    }`}
                  >
                    <td
                      scope="row"
                      className="px-3 py-3 flex flex-row space-x-3 items-center rtl:space-x-reverse"
                    >
                      <a>{formatNumber(v?.amount)}</a>
                    </td>
                    <td className="px-3">{v?.action}</td>
                    <td className="px-3">{v?.lenderId}</td>
                    <td className="px-3">{v?.type}</td>
                    <td className="px-3">{v?.walletName || "null"}</td>
                    <td className="px-3">
                      {v?.transaction?.active ? "true" : "false"}
                    </td>
                    <td className="px-3">{v?.transaction?.certificates}</td>
                    <td className="px-3">{v?.transaction?.lenderInternalId}</td>
                    <td className="px-3">
                      {v?.transaction?.lendersCustomerId || "null"}
                    </td>
                    <td className="px-3">{v?.transaction?.owner}</td>
                    <td className="px-3">{v?.transaction?.ownershipId}</td>
                    <td className="px-3">
                      {v?.transaction?.redeemAllowed ? "true" : "false"}
                    </td>
                    <td className="px-3">
                      {formatNumber(v?.transaction?.wallet)}
                    </td>
                    <td className="px-3">
                      {v?.transaction ? (
                        <div
                          onClick={() =>
                            window.open(
                              v?.transaction?.ownershipFileUrl,
                              "_blank",
                              "noopener"
                            )
                          }
                          className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                        >
                          {t("Owner Ship File")}
                        </div>
                      ) : (
                        "Not Found"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </CardMain>
    </div>
  );
}
export default withAuthorization(AllUsers);

let header = [
  { name: "Amount" },
  { name: "Action" },
  { name: "Lended Id" },
  { name: "Type" },
  { name: "Wallet Name" },
  { name: "Active" },
  { name: "Certificates" },
  { name: "Lender Internal Id" },
  { name: "lenders Customer Id" },
  { name: "owner" },
  { name: "ownership Id" },
  { name: "redeem Allowed" },
  { name: "Wallet" },
  { name: "File" },
];
